import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { ProductDetails } from "../../../modals/Product";
import AddToCartForm from "../../AddToCartForm";
import AskQuestion from "../../AskQuestion";
import { isValidImageUrl } from "../../../utils/common";
import { NO_IMG } from "../../../assets/images/images";
import Loader from "../../Loader";
import { generalMessages as MSG } from "../../../utils/constants";
import { GetProductDetailsService } from "../../../services/services";
import SubstituteProducts from "../../SubstituteProducts";

interface ParentProps {
  id: number;
  backToList: Function;
}

const SavedProductDetail: React.FC<ParentProps> = ({ id, backToList }) => {
  const [product, setProduct] = useState<ProductDetails>();
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      getProjectDetails(id);
    }
  }, []); // eslint-disable-line

  const getProjectDetails = async (id: number) => {
    console.log("id", id);
    const result = await GetProductDetailsService(id.toString());
    if (result.data.flag && result.data.data.length !== 0) {
      setProduct(result.data.data);
    } else {
      setStatus(MSG.PRODUCT_NOT_FOUND);
    }
  };

  return (
    <div>
      <div className="product-wrapper">
        <section className="product-details">
          <div className="container-xl">
            <Breadcrumb className="primary-breadcrumb">
              <Breadcrumb.Item onClick={() => backToList()}>
                <span className="text-truncate">Saved products</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <span className="text-truncate">{product?.name}</span>
              </Breadcrumb.Item>
            </Breadcrumb>

            {status ? (
              status
            ) : product ? (
              <div className="row">
                <div className="col-md-auto">
                  <div className="product-img">
                    <i>
                      <img
                        onError={() =>
                          setProduct({ ...product, product_image: NO_IMG })
                        }
                        src={
                          isValidImageUrl(product.product_image)
                            ? product.product_image
                            : NO_IMG
                        }
                        alt={product.product_image}
                      />
                    </i>
                  </div>
                </div>
                <div className="col-md">
                  <div className="product-micro-detail">
                    {product.product_model && (
                      <span className="product-model">
                        Product Code: {product.product_model}
                      </span>
                    )}
                    <h4 className="product-name">{product.name}</h4>
                    <span className="price">$ {product.price}</span>
                    {product.out_of_stock ? (
                      <div className="out-of-stock">
                        {product.out_of_stock ? "OUT OF STOCK" : ""}
                        <SubstituteProducts id={product.id} />
                      </div>
                    ) : (
                      ""
                    )}
                    <AddToCartForm {...product} />
                    <AskQuestion {...product} />
                    <div className="product-description">
                      <h6>Product Brand</h6>
                      <p>{product.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SavedProductDetail;
