import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { NO_IMG } from "../../../assets/images/images";
import { HistoryProduct as Product } from "../../../modals/Product";
import { isValidImageUrl } from "../../../utils/common";

//for add to cart
import ListingWidget from "../../AddToCartForm/ListingWidget";
import { ProductDetails } from "../../../modals/Product";
import { GetProductDetailsService } from "../../../services/services";
import { generalMessages as MSG } from "../../../utils/constants";

interface ParentProps {
  loadItem: Function;
}

const HistoryProduct: React.FC<Product & ParentProps> = ({
  product_image,
  product_name,
  product_id,
  amount,
  quantity,
  loadItem,
  out_of_stock,
  product_model,
}) => {
  const [image, setImage] = useState(product_image);
  const [product, setProduct] = useState<ProductDetails>();
  const [status, setStatus] = useState<string | null>(null);

  //add to cart
  // useEffect(() => {
  //   getProjectDetails(product_id);
  // }, []); // eslint-disable-line

  const getProjectDetails = async (product_id: number) => {
    const result = await GetProductDetailsService(product_id.toString());
    if (result.data.flag && result.data.data.length !== 0) {
      setProduct(result.data.data);
    } else {
      setStatus(MSG.PRODUCT_NOT_FOUND);
    }
  };

  return (
    <div className="product-list-item" onClick={() => {}}>
      <i className="product-list-item-img">
        <img
          onError={() => setImage(NO_IMG)}
          src={isValidImageUrl(product_image) ? product_image : NO_IMG}
          alt={product_image}
        />
      </i>
      <div className="product-list-item-detail">
        <div
          onClick={() => {
            loadItem();
          }}
        >
          <div className="label-action">
            {product_model && (
              <p className="product-model">Product Code: {product_model}</p>
            )}
            <span>{product_name}</span>
          </div>
          <span>$ {amount}</span>
        </div>
        <Form.Group className="ml-md-auto form-group">
          {/* <Form.Control
            type="text"
            onClick={(e) => e.stopPropagation()}
            className="form-control"
            placeholder="QTY."
            value={quantity}
            readOnly
          /> */}
          <ListingWidget
            id={product_id}
            is_saved={0}
            isListing
            out_of_stock={out_of_stock}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default HistoryProduct;
