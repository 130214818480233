import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  GetProductDetailsService,
  CategoryService,
} from "../../services/services";
import { ProductDetails as Product } from "../../modals/Product";
import { findNestedObj, getPathTo } from "../../utils/common";
import { history } from "../../utils/appConfig";
import { MODULES } from "../../utils/routesNames";
import AddToCartForm from "../AddToCartForm";
import AskQuestion from "../AskQuestion";
import { isValidImageUrl } from "../../utils/common";
import Loader from "../Loader";
import { generalMessages as MSG } from "../../utils/constants";
import { NO_IMG } from "../../assets/images/images";
import SubstituteProducts from "../SubstituteProducts";

const ProductDetails = ({
  match: {
    params: { productID },
  },
}) => {
  const [product, setProduct] = useState<Product>();
  const [root, setRoot] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    getCategories().then((res) => {
      getProductDetails(res);
    });
  }, []); // eslint-disable-line

  const getCategories = async () => {
    const result = await CategoryService();
    if (result.data.flag) {
      if (result.data.data.length !== 0) {
        return result.data.data;
      }
    } else {
      setStatus(MSG.ERROR_MSG);
    }
  };

  const getProductDetails = async (res: any) => {
    const result = await GetProductDetailsService(productID);
    if (result.data.flag && result.data.data) {
      setProduct(result.data.data);
      setStatus(null);
      setRoot(res);
      const item = findNestedObj(res, "id", result.data.data.category_id);
      const path = getPathTo(item ? item.name : "", res);
      setBreadCrumbs(path);
    } else {
      setStatus(MSG.PRODUCT_NOT_FOUND);
    }
  };

  const jumpToCategory = (name: string) => {
    const item = findNestedObj(root, "name", name);
    const path = getPathTo(item ? item.name : "", root);
    history.push(MODULES.allProducts.url, { item, path });
  };

  return (
    <div>
      <div className="product-wrapper">
        <section className="product-details">
          <div className="container-xl">
            <Breadcrumb className="primary-breadcrumb">
              {breadCrumbs &&
                breadCrumbs.map((item, index) => {
                  return index === breadCrumbs.length - 1 ? (
                    <Breadcrumb.Item
                      key={index}
                      onClick={() =>
                        history.push(
                          `${MODULES.productsList.url}/${product?.category_id}`
                        )
                      }
                    >
                      {item}
                    </Breadcrumb.Item>
                  ) : index === 0 ? (
                    <Breadcrumb.Item
                      key={index}
                      onClick={() => history.push(MODULES.allProducts.url)}
                    >
                      {item}
                    </Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item
                      key={index}
                      onClick={() => jumpToCategory(item)}
                    >
                      {item}
                    </Breadcrumb.Item>
                  );
                })}
              <Breadcrumb.Item active>
                <span className="text-truncate">{product?.name}</span>
              </Breadcrumb.Item>
            </Breadcrumb>

            {status ? (
              status
            ) : product ? (
              <div className="row">
                <div className="col-md-auto">
                  <div className="product-img">
                    <i>
                      <img
                        onError={() =>
                          setProduct({ ...product, product_image: NO_IMG })
                        }
                        src={
                          isValidImageUrl(product.product_image)
                            ? product.product_image
                            : NO_IMG
                        }
                        alt={product.product_image}
                      />
                    </i>
                  </div>
                </div>
                <div className="col-md">
                  <div className="product-micro-detail">
                    {product.product_model && (
                      <span className="product-model">
                        Product Code: {product.product_model}
                      </span>
                    )}
                    <h4 className="product-name">{product.name}</h4>
                    <span className="price">$ {product.price}</span>
                    {product.out_of_stock ? (
                      <div className="out-of-stock">
                        {product.out_of_stock ? "OUT OF STOCK" : ""}
                        <SubstituteProducts id={product.id} />
                      </div>
                    ) : (
                      ""
                    )}
                    <AddToCartForm {...product} />
                    <AskQuestion {...product} />
                    <div className="product-description">
                      <h6>Product Brand</h6>
                      <p>{product.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
export default ProductDetails;
